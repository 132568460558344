import { cookiesEnabled, extractFromCookie, noop, initIFrame, gtmSnippet, keyListener } from './utils';
import { showBanner, initBanner, initForm, initBannerListeners, initButtonListeners } from './ui';
import { necessary, apply } from './consent';
import { createStore } from '../shared/store';
import { initialState } from './reducers';

export default settings => {
    /* istanbul ignore next */
    if (!cookiesEnabled()) return;
    const Store = createStore();

    if (!Object.keys(settings.types).length === 0) return void console.warn('No cookie consent types have been defined.');

    //extractFromCookie adds a try/catch guard for cookie reading and JSON.parse in case of cookie name collisions caused by versioning
    //for sites that are saving the cookie consent in a different shape, i.e. without cid and consent properties
    //and for sites with cookies that are not base64 encoded
    const [ hasCookie, consent ] = extractFromCookie(settings);

    Store.update(
        initialState,
        {
            settings,
            bannerOpen: false,
            toggle: null,
            consent,
            focusableChildren: null,
            keyListener: keyListener(Store),
            utils: { initIFrame, gtmSnippet }
        },
        [
            necessary,
            apply(Store),
            hasCookie ? noop : initBanner(Store),
            initForm(Store),
            initButtonListeners(Store),
            initBannerListeners(Store)
        ]
    );

    return {
        getState: Store.getState,
        showBanner(cb) {
            showBanner(Store)(cb);
            initButtonListeners(Store)();
            initBannerListeners(Store)();
        },
        renderForm: initForm(Store)
    };
};